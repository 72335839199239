.gru-content-suggestion {
  @media only screen and (max-width: $screen-sm - 1) {
    top: 50px !important;
    width: 100%;
    left: 0;
    overflow-x: hidden;
    height: 100%;

    .modal-header {
      .suggestion-title {
        font-size: 22px;
      }
    }
  }
}
