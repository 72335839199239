.controller.sign-up {
  @media only screen and (max-width: $screen-sm - 1) {
    .modal {
      .modal-dialog {
        .modal-body {
          .sign-up-finish-form {
            .form-right {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.controller.sign-up {
  @media only screen and (max-width: $screen-sm - 1) {
    .modal {
      .modal-dialog {
        .modal-body {
          .sign-up-form {
            .form-group.lastName {
              margin-left: -15px;
            }

            .form-left,
            .form-right {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
