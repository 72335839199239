.activities.add-performance-data {
  @media only screen and (max-width: $screen-sm - 1) {
    height: 100%;

    .add-performance-data-container {
      .add-data-body-container {
        .add-student-performance-data {
          .student-performance-data-container {
            .assessment-performance-data-by-upload-image {
              display: block;
              padding: 0;

              .panels {
                .footer-panel {
                  bottom: 50px;
                }

                .left-panel {
                  display: none;
                  margin: 0;
                  max-height: 100vh;
                  background-color: $light-100;

                  .selected-image-container {
                    display: block;
                    height: calc(100% - 250px);
                    background-color: $white;
                  }

                  .collection-image-container,
                  .file-picker-container {
                    display: none;
                  }
                }

                .right-panel {
                  background-color: $right-panel-bg;
                  max-height: 100vh;

                  &.show-toggle {
                    .preview-panel {
                      .gru-i2d-preview {
                        .image-preview-container {
                          .footer-container {
                            bottom: 150px;

                            &.score-review {
                              bottom: 50px;
                            }
                          }
                        }
                      }
                    }
                  }

                  .preview-panel {
                    .gru-i2d-preview {
                      .image-to-data-preview-container {
                        .re-upload-container {
                          right: 30px;
                          bottom: 95px;
                          left: auto;
                          z-index: 2;
                        }

                        .footer-container {
                          bottom: 93px;
                          overflow-x: auto;
                          display: flex;

                          .action,
                          .preview-slides {
                            width: 100%;
                            height: auto;
                            justify-content: flex-start;
                            align-items: center;

                            button {
                              position: absolute;
                              right: 15px;
                              border: none;
                              border-radius: 50px;
                              bottom: 15px;
                              z-index: 9;
                              height: 60px;
                              background-color: $blue-ccc;
                              color: $white;
                            }

                            .submit-btn {
                              width: 60px;
                              display: grid !important;
                            }

                            .submit-score-btn {
                              width: auto;
                              display: flex !important;
                            }
                          }
                        }
                      }
                    }

                    .prev-message {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
