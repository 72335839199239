.gru-player-summary-footer {
  @media only screen and (max-width: $screen-767) {
    .nav-player-container {
      // grid-template-columns: 50px 1fr auto 50px;
      grid-template-rows: 1fr;
      grid-template-columns: auto;
      .player-container {
        width: calc(100vw - 150px);
      }

      .prev {
        width: 50px;

        span {
          display: none;
        }

        i {
          // margin-left: -100px;
          margin-left: 0px;
        }
      }

      // .next-action {
      //   width: 50px;

      .next {
        width: 50px;

        .next-text {
          display: none;
        }

        .exit {
          margin-left: 0;
          width: 100%;
          text-align: center;
        }

        i {
          // margin-left: -100px;
          margin-left: 0px;
        }
      }
      // }
    }
  }
}
