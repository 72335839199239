.student-locate {
  @media screen and (max-width: $screen-md - 1) {
    .student-locate-container {
      .activity-panel {
        .chrono-view {
          padding: 5px;

          .chrono-timeline {
            .container {
              padding: 5px;
              grid-template-areas: "timeline competency competency competency competency free" "timeline activities activities activities activities free";
              grid-template-columns: 59px 1fr;

              .competency-container {
                width: 100%;
                height: 77px;
                grid-column-start: 2;
                margin-left: 5px;
                grid-template-columns: 65px 1fr;
                grid-template-rows: none;

                .competency-progress-bar {
                  align-items: center;

                  .student-competency-progress {
                    width: 100%;
                    display: flex;

                    .completed,
                    .in-progress,
                    .not-started {
                      height: 12px;
                    }
                  }
                }
              }

              .header {
                height: 100%;
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }

  .student-locate-container {
    .activity-panel {
      @media screen and (max-width: 567px) {
        overflow: hidden;

        .chrono-view {
          .competency-container {
            width: 65px;
          }

          .chrono-timeline {
            display: grid;
            position: relative;
            width: 100%;

            .container {
              margin-right: auto;
              margin-left: auto;
              padding-left: 10px;
              padding-right: 0;

              #carousel {
                width: 100%;
                justify-content: center;

                .student-activity {
                  transition: transform 0.5s, top 1s, opacity 1s, z-index 0s;
                }

                .student-activity.selected {
                  z-index: 10;
                  left: auto;
                  top: calc(50vh - 40%);
                  right: auto;
                  transform: scale(1);
                }

                .student-activity.left-4 {
                  z-index: 5;
                  left: auto;
                  top: calc(50vh - 46%);
                  opacity: 0.9;
                  transform: scale(0.9);
                }

                .student-activity.left-3 {
                  z-index: 4;
                  left: auto;
                  top: calc(50vh - 51%);
                  opacity: 0.8;
                  transform: scale(0.8);
                }

                .student-activity.left-2 {
                  z-index: 3;
                  left: auto;
                  top: calc(50vh - 56%);
                  opacity: 0.7;
                  transform: scale(0.7);
                }

                .student-activity.left-1 {
                  left: auto;
                  z-index: 2;
                  opacity: 0.6;
                  top: calc(50vh - 61%);
                  transform: scale(0.6);
                  opacity: 0;
                }

                .student-activity.left-0 {
                  left: auto;
                  visibility: hidden;
                  top: calc(50vh - 66%);
                  transform: scale(0.5);
                  opacity: 0;
                }

                .student-activity.right-1 {
                  z-index: 5;
                  left: auto;
                  opacity: 0;
                  top: calc(50vh - 34%);
                  transform: scale(0.9);
                }

                .student-activity.right-2 {
                  z-index: 4;
                  left: auto;
                  opacity: 0;
                  top: calc(50vh - 30%);
                  transform: scale(0.8);
                }

                .student-activity.right-3 {
                  z-index: 3;
                  left: auto;
                  opacity: 0;
                  top: calc(50vh - 26%);
                  transform: scale(0.7);
                }

                .student-activity.right-4 {
                  z-index: 2;
                  left: auto;
                  opacity: 0;
                  top: calc(50vh - 22%);
                  transform: scale(0.6);
                }

                .student-activity.right-0 {
                  visibility: hidden;
                  left: auto;
                  opacity: 0;
                  top: calc(50vh - 18%);
                  transform: scale(0.5);
                }
              }
            }
          }
        }
      }
    }
  }
}
