.reports.pull-up-collection-report {
  @media only screen and (max-width: $screen-sm - 1) {
    width: 100%;

    .collection-report-container {
      width: 100%;
      @media only screen and (max-width: $screen-720) {
        .report-content {
          .report-carousel {
            .report-carousel-content-container {
              .report-carousel-content {
                .performance-display-container {
                  margin: auto;
                }

                .collection-report-chart {
                  display: none;
                }
              }
            }
          }

          .report-performance-tabular {
            .report-header-container {
              .taxonomy-details {
                display: none;
              }

              .report-action-btns {
                width: 100%;
              }
            }
          }
        }
      }

      table {
        th.tabular-header {
          position: static;
        }
      }
    }
  }
}
