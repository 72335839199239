.player {
  @media only screen and (max-width: $screen-sm) {
    .qz-question-viewer {
      .answers-panel,
      .question-panel {
        .panel {
          margin-top: 0;
          min-height: auto;
        }
      }
    }
  }
}
