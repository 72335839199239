.player {
  .container-fluid {
    .qz-player {
      overflow: auto;
      height: 100vh;

      .player {
        @media screen and (max-width: 767px) {
          height: calc(100% - 40px);
        }

        .qz-main {
          min-height: 100%;

          .qz-viewer {
            margin: 0;
            padding-bottom: 50px;

            .qz-content {
              .qz-resource-viewer {
                .qz-resource-viewer-panel {
                  @media only screen and (max-width: $screen-sm) {
                    .qz-resource.qz-youtube-resource {
                      iframe {
                        height: 100% !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
