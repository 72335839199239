.reports.pull-up-student-lesson-report {
  @media screen and (max-width: $screen-sm - 1) {
    width: 100%;

    .student-lesson-report-container {
      width: 100%;

      .report-header {
        .report-breadcrumb {
          .course-title p {
            max-width: calc(100% - 50px);
          }
        }

        .report-title {
          width: calc(100% - 50px);

          .title {
            width: 100%;
            font-size: 16px;
            line-height: 20px;
            padding-left: 15px;
            letter-spacing: normal;
            padding-top: 10px;
            color: $dark-400;

            p {
              margin: 0;
            }
          }
        }
      }

      .report-content {
        .report-performance-tabular {
          @media screen and (max-width: $screen-xs) {
            .report-performance-header {
              grid-template-columns: auto auto;

              .content-name {
                padding-left: 50px;
              }

              .score {
                padding-right: 10px;
                text-align: right;
              }
            }

            .report-performance-list {
              grid-template-columns: 90% 10%;

              .completion {
                display: none;
              }
            }
          }
        }

        .report-carousel-tags {
          .report-carousel {
            .report-carousel-content-container {
              .report-carousel-content {
                width: 100%;

                .performance-display-container {
                  margin: 0 auto;
                }

                .lesson-description {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.reports.pull-up-student-lesson-report {
  @media (orientation: landscape) {
    .student-lesson-report-container {
      .report-content {
        .report-carousel-tags {
          .report-carousel {
            .report-carousel-content-container {
              .report-carousel-content {
                .lesson-description {
                  display: block;
                  width: calc(100% - 300px);
                }
              }
            }
          }
        }
      }
    }
  }
}
