.library {
  .container {
    @media screen and (max-width: $screen-675) {
      .card-container {
        grid-template-columns: none;
      }
    }

    .partner-libraries {
      @media screen and (max-width: $screen-md - 1) {
        .libraries-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      @media screen and (max-width: $screen-675) {
        .libraries-container {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
