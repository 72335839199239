.oa-grading-report {
  @media only screen and (max-width: $screen-767) {
    width: 100%;

    .oa-grade-container {
      width: 100%;
      height: 85vh;

      .oa-grading-section {
        grid-template-columns: unset;
        overflow: auto;
      }
    }
  }
}
