.gru-external-assessment-footer {
	/*mobile view */
	@media only screen and (max-width: $screen-md - 1) {
		.nav-player-container {
			.prev {
				width: 35px;
				i {
					margin-left: -108px;
				}
				span {
					display: none;
				}
			}
			.player-container {
				width: calc(100% - 122px);
				.resource-title {
					font-size: 14px;
				}
			}
			.next-action {
				width: 36px;
				span {
					display: none;
				}
			}
		}
	}
	@media only screen and (orientation: landscape) {
		.nav-player-container {
			.prev.disabled {
		    width: 18%;
			}
			.prev {
				width: auto;
				i {
					margin-left: -108px;
				}
				span {
					display: block !important;
				}
			}
			.player-container {
				width: auto;
				.resource-title {
					font-size: 14px;
					span {
						margin-left: 61px;
					}
				}
			}
			.next-action {
				width: auto;
				span {
					display: block !important;
				}
			}
		}
	}
}
