.teacher-external-assessment-list-view {
  .list-view-container {
    @media only screen and (max-width: $screen-sm - 1) {
      .table-fixed-left {
        width: 100%;
      }

      .external-assessment-link {
        width: 100%;

        div {
          text-align: center;
        }
      }
    }
  }
}
