.reports.assessment.qz-resources {
  @media only screen and (max-width: $screen-md - 1) {
    .resources-header {
      text-align: center;
    }
  }
  @media only screen and (max-width: $screen-sm - 1) {
    .header {
      .title {
        display: none !important;
      }

      .icon {
        display: block !important;
      }
    }
  }
}
