.reports.pull-up-question-report {
  @media only screen and (max-width: $screen-sm - 1) {
    width: 100%;

    .question-report-container {
      width: 100%;

      .report-content {
        .report-info-chart-container {
          grid-template-columns: auto 1fr 100px;

          .report-chart-container {
            width: 100%;
          }
        }

        .report-carousel {
          #report-carousel-wrapper {
            .carousel-control.left,
            .carousel-control.right {
              margin-top: 5px;
              width: 30px;
              height: 30px;

              .arrow-left,
              .arrow-right {
                width: 25px;
                background-size: 25px 25px;
                height: 25px;
                top: 10%;
                margin-left: 0;
              }
            }

            .question-background-cover {
              .question-content-container {
                margin-top: 10px;
                width: 100%;

                .question-text {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
