.reports.student-external-assessment-report {
  @media screen and (max-width: $screen-sm - 1) {
    width: 100%;

    .report-container {
      width: 100%;

      .report-body {
        width: 100%;
      }

      .report-header {
        @media only screen and (max-width: $screen-600) {
          .assessment-container {
            padding: 10px;

            .assessment-info {
              margin-left: 0;
            }

            .assessment-thumbnail {
              display: none;
            }
          }

          .pullup-close {
            width: 50px;
          }
        }
      }
    }
  }
}
