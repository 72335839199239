.routesuggestion-coursemap {
  .route-suggestions-container {
    width: 98% !important;
    margin: auto;

    .gru-accordion-course,
    .gru-accordion-unit {
      padding: 0;
    }
    @media screen and (max-width: 767px) {
      .gru-accordion-course .panel-heading .panel-title > a {
        max-width: none;
        display: block;
      }

      .gru-accordion-lesson {
        .panel-collapse.collapse.in .collections .panel-heading .panel-title {
          width: 65%;
          display: flex;
          line-height: 60px;

          .title {
            width: calc(100% - 100px);
          }
        }

        .panel-heading {
          .panel-title {
            width: 75%;
          }

          .info {
            .performance .not-started {
              padding: 6px 5px 0;
            }
          }
        }
      }
    }
    @media screen and (max-width: 479px) {
      .gru-accordion-lesson {
        .panel-collapse.collapse.in .collections .panel-heading {
          .panel-title {
            width: 58%;

            .icon-container {
              width: 50px;
            }

            .title {
              width: calc(100% - 50px);
            }
          }

          .info {
            div.performance,
            div.play-btn {
              width: 50px;
            }

            .performance .not-started {
              padding: 6px 5px 0;
            }
          }
        }
      }
    }
    @media screen and (min-width: 320px) and (max-width: 374px) {
      .gru-accordion-lesson {
        .panel-heading {
          .panel-title {
            width: 72%;
          }
        }

        .panel-collapse.collapse.in .collections .panel-heading {
          .panel-title {
            width: 55%;
          }
        }
      }
    }

    .course-suggestions {
      @media screen and (max-width: $screen-sm - 1) {
        .gru-accordion-unit .unit + .collapse.in {
          padding: 4px;
        }

        .gru-accordion-unit .unit > .panel-title {
          padding-top: 0;
          height: 100%;

          a {
            height: 100%;

            .title {
              display: grid;
              grid-template-rows: max-content auto;
              padding-left: 10px;

              .prefix {
                border: none;
                font-size: 12px;
                margin: 0;
              }

              .ellipsis {
                font-size: 15px;
                font-style: normal;
                padding-left: 10px;
              }
            }
          }
        }

        .gru-accordion-lesson.expanded .panel-collapse.collapse.in {
          .panel-body {
            padding: 5px 5px 10px;
          }
        }

        .gru-accordion-lesson .panel-heading .panel-title {
          padding-top: 0;

          & > a {
            display: grid;
            grid-template-rows: max-content auto;

            .lesson-prefix {
              border: none;
              font-size: 12px;
            }

            .ellipsis {
              font-size: 15px;
              font-style: normal;
            }
          }
        }

        .gru-accordion-lesson .panel-collapse.collapse.in {
          .collections {
            .panel-heading {
              .panel-title {
                .title {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
