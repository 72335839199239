.reports.assessment.qz-summary {
  @media only screen and (max-width: $screen-md - 1) {
    .summary-container {
      .bubbles,
      .reactions {
        display: none;
      }

      .overview {
        padding: 0;

        h5 {
          width: 100%;
          height: 30px;
        }

        .information {
          margin: 0;
          .date {
            margin: 0 20px 0 0;
          }
        }
      }

      .grade {
        padding: 10px;
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: $screen-lg) and (min-width: $screen-md - 1) {
    .summary-container {
      .overview {
        h5 {
          width: 100%;
          height: max-content;
        }
      }
    }
  }
}
