.reports.dca-student-external-assessment-report {
  @media only screen and (max-width: $screen-568) {
    width: 100%;

    .report-container {
      width: 100%;
      .report-header {
       .assessment-container {
         .assessment-info {
           width: calc(100% - 75px);
         }
       }
        .assessment-container {
          .assessment-thumbnail {
            display: none;
          }
        }
      }
      .report-body {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: $screen-568) and (max-width: $screen-824) {
    width: 100%;

    .report-container {
      width: 100%;

      .report-body {
        width: 100%;
        height: 60vh;
        overflow-y: scroll;
      }
    }
  }
}
